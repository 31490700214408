import React from "react";
import hairImg1 from "../images/hair_img_1.png";
import hairImg2 from "../images/hair_img_2.png";
import hairImg3 from "../images/hair_img_3.png";
import hairImg4 from "../images/hair_img_4.png";
import hairImg5 from "../images/hair_img_5.png";
import hairImg6 from "../images/hair_img_6.png";
import instagramIcon from "../images/instagram_icon_lrg.png";

export const Testimonials = () => {
    return (
        <div className="testimonials">
            <div className="testimonials-background"></div>
            <div className="testimonial-images">
                <img className="test-image" src={hairImg1} alt="Hair" />
                <img className="test-image" src={hairImg2} alt="Hair" />
                <img className="test-image" src={hairImg3} alt="Hair" />
                <img className="test-image" src={hairImg4} alt="Hair" />
                <img className="test-image" src={hairImg5} alt="Hair" />
                <img className="test-image" src={hairImg6} alt="Hair" />
            </div>
            <div className="testimonial-link">
                <a
                    className="link"
                    href="https://www.instagram.com/dfordayne?igshid=ODBkMDk1MTU="
                >
                    <span className="testimonials-social">
                        Follow me on Instagram
                    </span>
                    <img
                        className="testimonial-icon"
                        src={instagramIcon}
                        alt="Instagram"
                    />
                </a>
            </div>
        </div>
    );
};
