import "./App.css";

import { About } from "./components/About";
import { Animated } from "react-animated-css";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Hero } from "./components/Hero";
import { Pricing } from "./components/Pricing";
import { Testimonials } from "./components/Testimonials";

function App() {
    return (
        <Animated>
            <div className="container">
                <Header />
                <Hero />
                <div className="verticalLineLeft" />
                <About />
                <div className="verticalLineRight" />
                <Testimonials />
                <div className="verticalLineLeft" />
                <Pricing />
                <div className="verticalLineRight" />
                <Footer />
            </div>
        </Animated>
    );
}

export default App;
