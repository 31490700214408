import React from "react";

export const Pricing = () => {
    return (
        <div className="pricing">
            <div className="pricing-title">Price List</div>
            <div className="prices">
                <div className="left">
                    <div className="haircuts">
                        <div className="pricing-header">HAIRCUTS</div>
                        <div className="pricing-description">
                            EVERY HAIRCUT SERVICE BEGINS WITH A DETAILED
                            CONSULTATIN LEADING TO PERSONALIZED RESULTS
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">CLIPPER SHORT</span>
                            <span className="service-price">$35</span>
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">SHORT</span>
                            <span className="service-price">$45</span>
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">MEDIUM</span>
                            <span className="service-price">$50</span>
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">LONG</span>
                            <span className="service-price">$65+</span>
                        </div>
                    </div>
                    <div className="shampoo">
                        <div className="pricing-header">SHAMPOO + BLOWDRY</div>
                        <div className="pricing-line">
                            <span className="service-name">SHORT</span>
                            <span className="service-price">$35</span>
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">LONG</span>
                            <span className="service-price">$40+</span>
                        </div>
                    </div>
                    <div className="treatments">
                        <div className="pricing-header">TREATMENTS</div>
                        <div className="pricing-line">
                            <span className="service-name">OLAPLEX</span>
                            <span className="service-price">$25</span>
                        </div>
                        <div className="pricing-description">
                            A BONDING TREATMENT TO STRENGTHEN ALL HAIR TYPES
                        </div>
                    </div>
                    <div className="curls">
                        <div className="pricing-header">CURL SERVICES</div>
                        <div className="pricing-line">
                            <span className="service-name">DRY CUT</span>
                            <span className="service-price">$50</span>
                        </div>
                        <div className="pricing-description">
                            THE DRY CUT IS A MUST FOR ANYONE WITH CURLS.
                            REDEFINING CLASSIC HAIR CUTTING TECHNIQUES FOR THE
                            ULTIMATE CURLY STYLE
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">
                                DRY CUT + RESET
                            </span>
                            <span className="service-price">$85</span>
                        </div>
                        <div className="pricing-description">
                            BEGINS WITH A DRY CUT SERVICE AND FINISHES WITH A
                            SHAMPOO AND STYLE THAT DEFINES AND ENHANCES YOUR
                            CURLS
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">
                                CURL REJUVENATION
                            </span>
                            <span className="service-price">$150</span>
                        </div>
                        <div className="pricing-description">
                            A SPECIALIZED EDUCATIONAL SERVICE THAT BEGINS WITH A
                            CURL ANALYSIS PINPOINTING THE IDEAL TREATMENT NEEDED
                            TO GIVE YOU THE BEST CURLS YOU HAVE EVER HAD. THIS
                            IS A PREMIUM, HANDS ON SERVICE IN WHICH WE WORK
                            TOGETHER TOWARDS BUILDING HEALTHY STYLE AND ENHANCED
                            CURL DEFINITION.
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="chemical">
                        <div className="pricing-header">CHEMICAL SERVICES</div>
                        <div className="pricing-description">
                            ALL CHEMICAL SERVICES INCLUDE A HAIRCUT
                        </div>

                        <div className="pricing-subheader">FOILS</div>
                        <div className="pricing-description">
                            AN EASY WAY TO ADD DEPTH, DIMENSION, AND CONTRAST
                            WITH LOW MAINTENANCE RESULTS
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">PARTIAL</span>
                            <span className="service-price">$115-130+</span>
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">FULL</span>
                            <span className="service-price">$155+</span>
                        </div>

                        <div className="pricing-subheader">COLOUR + FOILS</div>
                        <div className="pricing-description">
                            A COMPLETE TRANSFORMATION OF STYLE AND COLOUR.
                            STANDARD MAINTENANCE IS REQUIRED COMPARED TO A
                            FOIL-ONLY SERVICE
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">PARTIAL</span>
                            <span className="service-price">$145+</span>
                        </div>
                        <div className="pricing-line">
                            <span className="service-name">FULL</span>
                            <span className="service-price">$165+</span>
                        </div>

                        <div className="pricing-subheader">COLOUR</div>
                        <div className="pricing-line">
                            <span className="service-name">RETOUCH</span>
                            <span className="service-price">$110+</span>
                        </div>
                        <div className="pricing-description">
                            A TOUCH UP NEW GROWTH IN THE ROOT AREA
                        </div>

                        <div className="pricing-line">
                            <span className="service-name">ALL OVER</span>
                            <span className="service-price">$130+</span>
                        </div>
                        <div className="pricing-description">
                            TOTAL COLOUR COVERAGE FROM ROOT-TO-END. REQUIRES
                            REGULAR MAINTENANCE
                        </div>

                        <div className="pricing-line">
                            <span className="service-name">BLEACH RETOUCH</span>
                            <span className="service-price">$145</span>
                        </div>
                        <div className="pricing-description">
                            APPLICATION OF BLEACH ONLY AT THE NEW GROWTH AND ALL
                            OVER TONE
                        </div>

                        <div className="pricing-line">
                            <span className="service-name">BALAYAGE</span>
                            <span className="service-price">$175+</span>
                        </div>
                        <div className="pricing-description">
                            AN APPLICATION OF COLOUR OR LIGHTENER TO THE HAIR
                            USING A VARIETY OF SWEEPING TECHNIQUES, ENHANCING
                            DIMENSION OF YOUR NATURAL COLOUR AND RESULTING IN A
                            SOFT FINISH. REQUIRES MINIMUM MAINTENANCE.
                        </div>

                        <div className="pricing-line">
                            <span className="service-name">CUSTOM COLOUR</span>
                            <span className="service-price">$100/HOUR</span>
                        </div>
                        <div className="pricing-description">
                            AN INDIVIDUALIZED SERVICE USING A VARIETY OF
                            PROCESSESS - A TOTAL HAIR MAKEOVER. THIS WOULD
                            INCLUDE SERVICES SUCH AS ALL-OVER BLEACHING, AND
                            FASHION COLOURS. <br /> <br />
                            *Includes initial consultation <br />
                            *A deposit and/or multiple appointments may be
                            required.
                        </div>

                        <div className="pricing-line">
                            <span className="service-name">
                                CORRECTIVE COLOUR
                            </span>
                            <span className="service-price">$100/HOUR</span>
                        </div>
                        <div className="pricing-description">
                            EVERYONE MAKES MISTAKES. THIS SERVICE IS EXACTLY
                            WHAT YOU THINK - I FIX THE UNDESIREABLE RESULTS OF
                            PREVIOUS COLOUR TREATMENTS
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
