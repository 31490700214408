import React from "react";
import heroImage1 from "../images/hero_img_1.png";
import heroImage2 from "../images/hero_img_2.png";
import heroImage3 from "../images/hero_img_3.png";

export const Hero = () => {
    return (
        <div className="hero">
            <img className="heroImage" src={heroImage1} alt="salon" />
            <img className="heroImage" src={heroImage2} alt="stylist" />
            <img className="heroImage" src={heroImage3} alt="stylist" />
        </div>
    );
};
