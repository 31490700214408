import React from "react";
import emailIcon from "../images/email_icon.png";
import instagramIcon from "../images/instagram_icon.png";
import phoneIcon from "../images/phone_icon.png";
import pinIcon from "../images/pin_icon.png";

export const Header = () => {
    return (
        <div className="header">
            <div className="logo">
                <span className="title">Bespoke</span>
                <span className="subtitle">By Dayne</span>
            </div>

            <div className="verticalLine" />

            <div className="contact">
                <div className="contactLine">
                    <img className="icon" src={pinIcon} alt="location" />
                    SALONS ON YORK - 401 YORK ST, FREDERICTON, NB
                </div>

                <div className="contactLine">
                    <a className="link" href="mailto:daynekenny23@outlook.com">
                        <img className="icon" src={emailIcon} alt="email" />
                        DAYNEKENNY23@OUTLOOK.COM
                    </a>
                </div>

                <div className="contactLine">
                    <a className="link" href="tel:+5066254783">
                        <img className="icon" src={phoneIcon} alt="phone" />
                        (506) 625-4783
                    </a>
                </div>

                <div className="contactLine">
                    <a
                        className="link"
                        href="https://instagram.com/dfordayne?igshid=ODBkMDk1MTU="
                    >
                        <img
                            className="icon"
                            src={instagramIcon}
                            alt="instagram"
                        />
                        @DFORDAYNE
                    </a>
                </div>
            </div>
        </div>
    );
};
