import React from "react";
import emailIcon from "../images/email_icon_dark.png";
import instagramIcon from "../images/instagram_icon_dark.png";
import phoneIcon from "../images/phone_icon_dark.png";
import pinIcon from "../images/pin_icon_dark.png";

export const Footer = () => {
    return (
        <div className="footer">
            <div className="social">
                <div className="footerContactLine">
                    <img className="icon" src={pinIcon} alt="location" />
                    SALONS ON YORK - 401 YORK ST, FREDERICTON, NB
                </div>

                <div className="footerContactLine">
                    <a
                        className="footerLink"
                        href="mailto:daynekenny23@outlook.com"
                    >
                        <img className="icon" src={emailIcon} alt="email" />
                        DAYNEKENNY23@OUTLOOK.COM
                    </a>
                </div>

                <div className="footerContactLine">
                    <a className="footerLink" href="tel:+5066254783">
                        <img className="icon" src={phoneIcon} alt="phone" />
                        (506) 625-4783
                    </a>
                </div>

                <div className="footerContactLine">
                    <a
                        className="footerLink"
                        href="https://instagram.com/dfordayne?igshid=ODBkMDk1MTU="
                    >
                        <img
                            className="icon"
                            src={instagramIcon}
                            alt="instagram"
                        />
                        @DFORDAYNE
                    </a>
                </div>
            </div>
        </div>
    );
};
